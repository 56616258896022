import React from'react'
//import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage } from'gatsby-plugin-image'
import { graphql } from'gatsby'
import {Box,Flex,Heading,Text,Spinner} from'@chakra-ui/react'
import { MyToast } from '../components/toast';

import { useSyntext } from "../hooks/synagogen/use-syntext"
import MeinSeo from '../components/seo'

// const options ={
//   buttons: {
//     showDownloadButton: false
//   },
//   thumbnails: {
//     showThumbnails: true,
//     thumbnailsAlignment:'center',
//     thumbnailsContainerBackgroundColor:'transparent',
//     thumbnailsContainerPadding:'0',
//     thumbnailsGap:'0 1px',
//     thumbnailsIconColor:'#ffffff',
//     thumbnailsOpacity: 0.4,
//     thumbnailsPosition:'bottom',
//     thumbnailsSize: ['80px','80px']
//   }
// }

  function Synagogen({data}) {
   const syntext= useSyntext()
   console.log(syntext)
   if(syntext.edges){
    return (
<Box pb="100px" pt="10" maxWidth="800">
  <MeinSeo title="Fotos von Synagogen aus dem Hunsrueck" description='Historische Fotos von Synagogen aus dem HunsrÃžck'/>
      <div className="MyComponent">

        {/* <SRLWrapper options={options}> */}
        <Heading variant="Titel"
         >Synagogen - Historische Fotos </Heading>
          
             
              <MyToast />
        <Flex flexWrap="wrap">
        {data.images.edges.map((e,index) => {

          return (
             <Box key={index} width={["40%","45%","30%"]}>
              <Box  m="2"   border="1px solid black" position="relative"  maxWidth={[250]}  cursor="pointer" >
             
             <GatsbyImage

image={e.node.childImageSharp.gatsbyImageData}

alt={syntext.edges[index]!==undefined?syntext.edges[index].node.ort:""}

/>
<Box   width="100%" bottom="0" position="absolute" mx="auto" zIndex="10" > 
<Text  backgroundColor="black"  textAlign="center" color="white"  p="0"  
 fontSize="xs">{syntext.edges[index]?.node.ort!==undefined?syntext.edges[index].node.ort:""}</Text></Box>
</Box>
</Box>
          )

        })}
    </Flex>
        {/* </SRLWrapper> */}

      </div>
      </Box>
    );

  }
  return(<Spinner />)
}


  export const query = graphql`

  {

    images: allFile(
      filter: { relativeDirectory: { eq: "synagogen" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {

        node {

          name

          id

          relativePath

          childImageSharp {
           
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              height: 250
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
                
              }
              
            )
          }

        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "Synagogen/thumbnails" } }
        sort: { order: ASC, fields: name }
  
      ) {
  
        edges {
  
          node {
  
            name
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: FIXED
  
                width: 120
  
                height: 120
  
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default Synagogen;



