import { graphql, useStaticQuery } from "gatsby"


export const useSyntext = () => {
  const data = useStaticQuery(graphql`
  query{
    allSynagogenYaml(sort: {fields: nr}) {
      edges {
        node {
          nr
          ort
        }
      }
    }
     }
    

  `)

  return data.allSynagogenYaml
}